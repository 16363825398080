import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank-page',
  template: `<iframe src="/assets/homes/homes.page.html" style="width: 100%; height: 100vh; border: none;"></iframe>`,
})
export class BlankPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    const hasVisitedHome = sessionStorage.getItem('hasVisitedHome');

    if (!hasVisitedHome) {
      sessionStorage.setItem('hasVisitedHome', 'true');
      window.location.reload();
    }
  }
}
